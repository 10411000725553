<template>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        mode="subscription"
        :pk="publishableKey"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="v => loading = v"
      />
      <button @click="submit">Subscribe!</button>
    </div>
  </template>
  
  <script>
  import { StripeCheckout } from '@vue-stripe/vue-stripe';
  export default {
    components: {
      StripeCheckout,
    },
    data () {
      this.publishableKey = 'pk_live_51NjgbLBCZpJOB7vqpexAno3xsg5HjEN5O4mT0sYy9h8dS9xn2sZBU4oun5sQMe1QhYIsHjJIYtjGwDqDNRGROJWw00YIzTipHU';
      return {
        loading: false,
        lineItems: [
          {
            price: 'price_1OKyevBCZpJOB7vqaSueNBr7', // The id of the recurring price you created in your Stripe dashboard
            quantity: 1,
          },
        ],
        successURL: window.location.origin,
        cancelURL: window.location.origin,
      };
    },
    methods: {
      submit () {
        // You will be redirected to Stripe's secure checkout page
        this.$refs.checkoutRef.redirectToCheckout();
      },
    },
  };
  </script>